import React from "react"
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import queryString from 'query-string'
import { Helmet } from "react-helmet";

function handleSubmitClicked(planId) {
  var queryParams = location.search ? queryString.parse(location.search) : {};
  var stripe = Stripe('pk_live_18zrZABde5k3LgQ715MsEiHJ009QyYVtln');
  if (!document.getElementById("domain").value || !document.getElementById("email").value) {
    alert("Email address and domain are both required.");
    return;
  }
  var request = new XMLHttpRequest();
  request.open('GET', `https://usehyperlink.com/updateSubscription?domain=${encodeURIComponent(document.getElementById("domain").value)}&owned=${encodeURIComponent(document.getElementById("owned").checked ? "owned" : "not-owned")}&email=${encodeURIComponent(document.getElementById("email").value)}&ref=${encodeURIComponent(queryParams.ref)}&key=${encodeURIComponent(queryParams.id)}`);
  request.send();
  stripe.redirectToCheckout({
    items: [{ plan: planId, quantity: 1 }],
    customerEmail: document.getElementById("email").value,
    successUrl: 'http://usehyperlink.com/subscription/success?id=' + queryParams.id + '&ref=' + queryParams.ref + '&email=' + document.getElementById("email").value,
    cancelUrl: 'http://usehyperlink.com/subscription/?action=cancel&id=' + queryParams.id,
    clientReferenceId: queryParams.id + '-' + document.getElementById("domain").value
  })
    .then((result) => {
      if (result.error) alert(result.error.message || result.error);
    }).catch((error) => {
      if (error) alert(error.message || error);
    });
}


function SubscriptionSection({ location, planId }) {
  var queryParams = location.search ? queryString.parse(location.search) : {};
  return (
    <div className="container subscription">
      <Helmet>
        <script src="https://js.stripe.com/v3"></script>
      </Helmet>
      <h1>Pick Your Custom Domain</h1>
      <div className="form">
        <div className="input">
          <input id="email" className={/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(queryParams.email) ? "hidden" : ""} defaultValue={queryParams.email} placeholder="your@email.com" autoCapitalize="none" type="email" autoComplete="email" />
          <input id="domain" defaultValue={queryParams.domain} placeholder="yourdomain.com" autoCapitalize="none" type="url" autoComplete="username" />
          <div className="check">
            <input defaultChecked={queryParams.owned == "owned"} id="owned" name="owned" type="checkbox" /><label htmlFor="owned">I already
        own this domain</label>
          </div>
        </div>
        <div className="submit">
          <button onClick={() => handleSubmitClicked(planId)} id="checkout-button" role="link">
            Get it!
    </button>
        </div>
        <div className="footer">
          Custom domains can take 24 hours to be processed and activated.
  </div>
      </div>
    </div>

  );
}

export default SubscriptionSection;