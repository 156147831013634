import React from "react"
import { Link, graphql } from "gatsby"
import queryString from 'query-string'

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import SubscriptionSection from "../../components/subscription_section"

class Subscription extends React.Component {

  componentWillMount() {
    var queryParams = (typeof window !== 'undefined') && document.referrer ? queryString.parse(document.referrer.substring(document.referrer.indexOf("?"))) : {};
    if (queryParams.ref) {
      window.history.replaceState(null, null, "?ref=" + queryParams.ref);
    }
  }

  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Hyperlink" slug="/subscription/yc/" />
        <SubscriptionSection planId="plan_Gfxyc0H91mJvGl" location={this.props.location} />
      </Layout>
    )
  }
}

export default Subscription

export const pageQuery = graphql`
  query {
          site {
          siteMetadata {
          title
        }
        }
    allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC }) {
          edges {
          node {
          excerpt
          fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
      title
      description
    }
  }
}
}
}
`
